<template>
  <div class="i-input">
    <div v-if="label" class="field-label">
      {{ label }}
    </div>

    <div class="input-container" :class="iconPosition">
      <input
        :type="fieldType"
        :disabled="disabled"
        :value="inputValue"
        :name="name"
        :placeholder="placeholder"
        :class="{['error-input'] : showError && minLength && (inputValue.length <= minLength && inputValue.length !== 0)}"
        :ref="name"
        :autofocus="autofocus"
        @keydown.space="(event) => fieldType==='email' ? event.preventDefault() : false"
        @paste="removeEventSpaces"
        @drop="removeEventSpaces"
        @input="onInput($event.target.value)"
      />
      <div v-if="iconImage && !isIconClickable" class="icon-container">
        <img class="btn-icon" :src="changeImg(iconImage)">
      </div>
      <div v-else-if="iconImage && isIconClickable" class="icon-container">
        <img v-if="!value" class="btn-icon" src="../../assets/app/icons-disabled/icons/search.svg">
        <img v-else class="btn-icon icon-clickable" :src="changeImg(iconImage)" @click="clearInput"/>
      </div>

      <div v-if="icon && !isIconClickable" class="icon-container">
        <font-awesome-icon class="btn-icon" :icon="changeIcon(icon)"/>
      </div>

      <div v-else-if="icon && isIconClickable" class="icon-container">
        <font-awesome-icon v-if="!value" icon="fa-duotone fa-search"/>
        <font-awesome-icon v-else class="btn-icon icon-clickable" :icon="changeIcon(icon)" @click="clearInput"/>
      </div>

    </div>

    <div v-if="showError && minLength && (inputValue.length <= minLength && inputValue.length !== 0)" class="error-msg">
      {{ `Enter at least ${minLength} characters` }}
    </div>
    <div v-else-if="showError && minLength && (inputValue.length >= maxLength)" class="error-msg">
      {{ `Only ${maxLength} characters allowed` }}
    </div>
  </div>
</template>

<script>
import {debounce} from "@/services/common";

export default {
  name: "IInput",
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    fieldType: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: String,
      default: '' //left,right
    },
    autofocus: {
      type: String
    },
    icon: {
      type: String
    },
    iconImage: {
      type: String
    },
    isIconClickable: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: [String, Number],
      default: 0
    },
    maxLength: {
      type: [String, Number],
    },
  },

  data: () => ({
    showError: false,
    inputValue: null
  }),

  methods: {
    onInput(value) {
      this.inputValue = value;
      this.showError = true;

      if (
        (this.minLength && (value.length >= this.minLength || value.length === 0)) &&
        (this.maxLength && (value.length <= this.maxLength))
      ) {
        this.showError = false;
        this.$emit('input', value);
      } else if (this.maxLength && (value.length >= this.maxLength)) {
        this.inputValue = value.substring(0, this.maxLength);
        this.$emit('input', this.$refs[this.name].value = value.substring(0, this.maxLength));
      } else if (!this.minLength || !this.maxLength) {
        this.$emit('input', value);
      }
    },

    changeImg(img) {
      return require(`../../assets/app/${img}`)
    },
    changeIcon(icon) {
      return `fa-duotone ${icon}`
    },
    clearInput() {
      this.showError = false;
      this.$emit('input', '')
    },
    removeEventSpaces(e) {
      if (this.fieldType === 'email') {
        e.preventDefault();
        const left = e.target.value.substring(0, e.target.selectionStart);
        const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
        const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
        e.target.value = left + pasted + right;
        this.$nextTick().then(() => this.$emit("input", e.target.value))
      }
    },
  },

  created() {
    if (this.debounce) {
      this.onInput = debounce(this.onInput, 300);
    }
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs[this.autofocus].focus()
      })
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.inputValue = newVal
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import "IInput";
</style>
