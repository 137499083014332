import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listSnapshotDetails = ({userIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('google/drive/search', {userIds, folderId, snapshotDateTime, pagination, searchTerm: trimString(searchTerm)})
}

const listBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('google/drive/breadcrumbs', {userId, folderId, snapshotDate})
}


export {
  listSnapshotDetails,
  listBreadcrumps
}
