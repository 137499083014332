import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listConversation = ({teamIds, folderId, snapshotDateTime, pagination, searchTerm, channelIds}) => {
  return POST('recovery/team/chat/search', {teamIds, folderId, snapshotDateTime, pagination, searchTerm: trimString(searchTerm), channelIds})
}

const listBreadcrumps = ({teamId, folderId, snapshotDate, channelId}) => {
  return POST('recovery/team/chat/breadcrumbs', {teamId, channelId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, channelIds, code}) => {
  return POST('recovery/team/chat/export', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), selectedIds, channelIds}, {code: code ? code : null})
}

export {
  listConversation,
  listBreadcrumps,
  exportSnapshot
}
