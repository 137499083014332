import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listSnapshotDetails = ({sharedFolderIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('dropbox/shared-folder-snapshot/search', {sharedFolderIds, folderId, snapshotDateTime, pagination, searchTerm: trimString(searchTerm)})
}

const listBreadcrumps = ({sharedFolderId, folderId, snapshotDate}) => {
  return POST('dropbox/shared-folder-snapshot/breadcrumbs', {sharedFolderId, folderId, snapshotDate})
}


export {
  listSnapshotDetails,
  listBreadcrumps,
}
