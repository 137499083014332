import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listDrive = ({teamIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('recovery/team/drive/search', {teamIds, folderId, snapshotDateTime, pagination, searchTerm: trimString(searchTerm)})
}

const listBreadcrumps = ({teamId, folderId, snapshotDate}) => {
  return POST('recovery/team/drive/breadcrumbs', {teamId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, code}) => {
  return POST('recovery/team/drive/export', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('recovery/team/drive/restore', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), destinationId, snapshotId, selectedIds}, {code: code ? code : null})
}


export {
  listDrive,
  listBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
