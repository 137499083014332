import {POST} from "@/services/gateway";
import {trimString} from "@/components/common/utils/trimString";

const listLists = ({siteIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('recovery/sites/list/search', {siteIds, folderId, snapshotDateTime, pagination, searchTerm: trimString(searchTerm)})
}

const listListsBreadcrumps = ({siteId, folderId, snapshotDate}) => {
  return POST('recovery/sites/list/breadcrumbs', {siteId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, code}) => {
  return POST('recovery/sites/list/export', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('recovery/sites/list/restore', {snapshotDateTime, sourceIds, searchTerm: trimString(searchTerm), destinationId, snapshotId, selectedIds}, {code: code ? code : null})
}


export {
  listLists,
  listListsBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
